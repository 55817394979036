<template>
  <div>
    <h2 class="blind-a11y">테마 편집창</h2>
    <div
      class="DAV-workshop__top"
      :class="{
        'DAV-workshop__top--option-mode': this.$route.path === '/option'
      }"
    >
      <button
        v-show="getPanel === 'ComponentOption' || getPanel === 'EditableLayout'"
        class="DAV-workshop__prev"
        :class="{
          'DAV-workshop__prev--option':
            getPanel === 'EditableLayout' ||
            getPopup.page === 'ComponentImage' ||
            getPopup.page === 'ComponentIcon' ||
            getPopup.page?.includes('ComponentMulti') ||
            getPopup.page?.includes('ComponentProduct')
        }"
        @click="passPanel"
      >
        이전
      </button>
      <button
        class="DAV-workshop__controlbar"
        :class="{
          'DAV-workshop__controlbar--option-mode':
            this.$route.path === '/option'
        }"
      >
        편집창 조절
      </button>
      <button
        class="DAV-workshop__button"
        :class="{
          'DAV-workshop__button--option-mode': this.$route.path === '/option'
        }"
        @click="$emit('resizeWorkshop', propsWorkshopPosition)"
      >
        <span
          class="DAV-workshop__button--show"
          v-if="propsWorkshopPosition === 'bottom'"
        >
          편집창 열기
        </span>
        <span v-else>편집창 접기</span>
      </button>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleEditor = createNamespacedHelpers("ModuleEditor");

export default {
  props: ["propsWorkshopPosition"],
  computed: {
    ...ModuleEditor.mapGetters(["getPanel", "getPopup"])
  },
  methods: {
    // 이전 페이지로 이동
    passPanel() {
      if (this.getPopup.page === "ComponentImage") {
        this.$emit("passPanel", "image");
      } else if (this.getPopup.page === "ComponentIcon") {
        this.$emit("passPanel", "icon");
      } else if (this.getPopup.page === "ComponentMultiText") {
        this.$emit("passPanel", "multiText");
      } else if (this.getPopup.page === "ComponentMultiTextURL") {
        this.$emit("passPanel", "multiTextURL");
      } else if (this.getPopup.page === "ComponentMultiImage") {
        this.$emit("passPanel", "multiImage");
      } else if (this.getPopup.page === "ComponentMultiImageURL") {
        this.$emit("passPanel", "multiImageURL");
      } else if (this.getPopup.page === "ComponentMultiTextImageURL") {
        this.$emit("passPanel", "multiTextImageURL");
      } else if (this.getPopup.page === "ComponentProductAdd") {
        this.$emit("passPanel", "productAdd");
      } else if (this.getPopup.page === "ComponentProductList") {
        this.$emit("passPanel", "productList");
      } else {
        this.$emit("passPanel");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.DAV-workshop__top {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 56px;
  border-radius: 16px 16px 0px 0px;
  background-color: #54c7a2;
  &--option-mode {
    border-radius: unset;
  }
}
.DAV-workshop__prev {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  background-image: url($path + "bul_arr_left.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-indent: -9999px;
}
.DAV-workshop__controlbar {
  z-index: 2;
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
  width: 56px;
  height: 12px;
  background-image: url($path + "ico_controlbar.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  text-indent: -9999px;
  &--option-mode {
    display: none;
  }
}
.DAV-workshop__button {
  z-index: 2;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 104px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 24px;
  &--option-mode {
    display: none;
  }
  span {
    padding-right: 14px;
    color: #ffffff;
    font-size: 12px;
    background-image: url($path + "bul_arr_bottom.svg");
    background-repeat: no-repeat;
    background-position: right center;
  }
  &--show {
    background-image: url($path + "bul_arr_top.svg") !important;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-workshop__prev {
    top: 92px;
  }
  .DAV-workshop__prev--option {
    top: 72px;
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
}
</style>
