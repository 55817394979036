var render = function () {
  var _vm$getPopup$page, _vm$getPopup$page2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', {
    staticClass: "blind-a11y"
  }, [_vm._v("테마 편집창")]), _c('div', {
    staticClass: "DAV-workshop__top",
    class: {
      'DAV-workshop__top--option-mode': this.$route.path === '/option'
    }
  }, [_c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getPanel === 'ComponentOption' || _vm.getPanel === 'EditableLayout',
      expression: "getPanel === 'ComponentOption' || getPanel === 'EditableLayout'"
    }],
    staticClass: "DAV-workshop__prev",
    class: {
      'DAV-workshop__prev--option': _vm.getPanel === 'EditableLayout' || _vm.getPopup.page === 'ComponentImage' || _vm.getPopup.page === 'ComponentIcon' || ((_vm$getPopup$page = _vm.getPopup.page) === null || _vm$getPopup$page === void 0 ? void 0 : _vm$getPopup$page.includes('ComponentMulti')) || ((_vm$getPopup$page2 = _vm.getPopup.page) === null || _vm$getPopup$page2 === void 0 ? void 0 : _vm$getPopup$page2.includes('ComponentProduct'))
    },
    on: {
      "click": _vm.passPanel
    }
  }, [_vm._v(" 이전 ")]), _c('button', {
    staticClass: "DAV-workshop__controlbar",
    class: {
      'DAV-workshop__controlbar--option-mode': this.$route.path === '/option'
    }
  }, [_vm._v(" 편집창 조절 ")]), _c('button', {
    staticClass: "DAV-workshop__button",
    class: {
      'DAV-workshop__button--option-mode': this.$route.path === '/option'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('resizeWorkshop', _vm.propsWorkshopPosition);
      }
    }
  }, [_vm.propsWorkshopPosition === 'bottom' ? _c('span', {
    staticClass: "DAV-workshop__button--show"
  }, [_vm._v(" 편집창 열기 ")]) : _c('span', [_vm._v("편집창 접기")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }